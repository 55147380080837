var module = {
  ar: {
    locale: 'ar-SA',
    messages: {
      all: 'الكل',
      section: 'أقسام',
      prdocut_empty: 'لايوجد منتجات',
      topbar_title: 'Welcome to Our store Multikart',
      call_us: 'Call Us',
      home: 'الرئيسية',
      features: 'features',
      new: 'جديد',
      blog: 'الاخبار',
      blog_left_sidebar: 'left sidebar',
      blog_right_sidebar: 'right sidebar',
      blog_detail: 'تفاصيل الخبر',
      category_left_sidebar: 'left sidebar',
      category_right_sidebar: 'right sidebar',
      category_no_sidebar: 'no sidebar',
      category_metro: 'metro',
      category_full_width: 'full width',
      products: 'المنتجات',
      left_sidebar: 'left sidebar',
      right_sidebar: 'right sidebar',
      no_sidebar: 'no sidebar',
      blog_empty: 'لايوجد اخبار حاليا',
      brands: 'العلامات التجارية',
      three_col_thumbnail_left: 'Thumbnail left',
      three_col_thumbnail_right: 'Thumbnail right',
      thumbnail_below: 'thumbnail below',
      accordian_details: 'accordian details',
      thumbnail_left: 'image left',
      thumbnail_right: 'image right',
      vertical_tab: 'vertical tab',
      pages: 'الصفحات',
      about_us: 'معلومات عنا',
      lookbook: 'lookbook',
      login: 'تسجيل الدخول',
      register: 'register',
      logout: 'Logout',
      new_brands: 'العلامات التجارية الجديدة',
      pages: 'الصفحات',
      search: 'بحث',
      wishlist: 'wishlist',
      cart: 'cart',
      fax: 'فاكس',
      collection: 'collection',
      forget_password: 'forget password',
      contact: 'تواصل معنا',
      address: 'العنوان',
      checkout: 'checkout',
      compare: 'compare',
      order_success: 'order_success',
      dashboard: 'Dashboard',
      FAQ: 'FAQ',
      shop: 'Shop',
      mens_fashion: 'mens fashion',
      top: 'top',
      bottom: 'bottom',
      ethic_wear: 'ethic wear',
      sports_wear: 'sports wear',
      shirts: 'shirts',
      women_fashion: 'womens fashion',
      dresses: 'dresses',
      skirts: 'skirts',
      westarn_wear: 'westarn wear',
      ethnic_wear: 'ethnic wear',
      sport_wear: 'aport wear',
      bottom_wear: 'bottom wear',
      kids_fashion: 'kidss fashion',
      accessories: 'Accessories',
      fashion_jewellery: 'Fashion Jewellery',
      caps_and_hats: 'caps and hats',
      precious_jewellery: 'precious jewellery',
      necklaces: 'necklaces',
      earrings: 'earrings',
      rings_wrist_wear: 'rings & wrist wear',
      men_accessories: 'mens accessories',
      ties: 'ties',
      cufflinks: 'cufflinks',
      pockets_squares: 'pockets squares',
      helmets: 'helmets',
      scarves: 'scarves',
      phone_cases: 'phone cases',
      my_account: 'My Account',
      fashion: 'Fashion',
      beauty: 'Beauty',
      electronic: 'Electronic',
      furniture: 'Furniture',
      kids: 'Kids',
      pets: 'Pets',
      new_products: 'منتجات جديدة',
      email_address: 'البريد الالكتروني',
      fax: 'الفاكس',
      firstName: 'الاسم الاول',
      lastName: 'الاسم الاخير',
      email: 'بريد الالكتروني',
      write_your_message: 'من فضلك اكتب رسالك هنا',
      send_your_message: 'إرسال الرسالة',
      successfully_sent_request: 'نجح ارسال طلبك سيتم رد في اقرب وقت',
      loading: '....جاري تحميل',
      vegetables: 'Vegetables',
      watch: 'Watch',
      theme_elements: 'theme elements',
      element_title: 'element title',
      collection_banner: 'collection banner',
      home_slider: 'home slider',
      category: 'category',
      service: 'service',
      image_size_ratio: 'image size ratio',
      product_elements: 'product elements',
      product_box: 'product box',
      product_slider: 'product slider',
      no_slider: 'no slider',
      multi_slider: 'multi slider',
      tab: 'tab',
      email_template: 'email template',
      portfolio: 'portfolio',
      portfolio_grid_2: 'portfolio grid 2',
      portfolio_grid_3: 'portfolio grid 3',
      portfolio_grid_4: 'portfolio grid 4',
      portfolio_masonary_2: 'masonary 2',
      portfolio_masonary_3: 'masonary 3',
      portfolio_masonary_4: 'masonary 4',
      portfolio_masonary_full: 'masonary full width',
      call_us: 'هاتف',
      email_us: 'البريد الالكتروني',
      visit: 'زيارة',
      now: 'الان',
      showing_products: 'المنتجات',
      product_count: 'مجموع المنتجات',
      blog_details: 'تفاصيل المدونة',
      Vendors: 'المزود',
      product_details: 'الوصف',
      option: 'المواصفات',
      details: 'تفاصيل',
    },
  },
  en: {
    locale: 'en_US',
    messages: {
      all: 'All',
      blog_details: 'Blog - Details',
      prdocut_empty: 'Products is Empty',
      email_us: 'Email Us',
      product_count: 'Product ',
      call_us: 'Call Us',
      new_products: 'New Products',
      address: 'Address',
      email_address: 'Email Address',
      fax: 'Fax',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      pages: 'pages',
      enter_first_name: 'Enter First Name',
      enter_last_name: 'Enter Last Name',
      enter_email: 'Enter email',
      write_your_message: 'Write Your Message',
      send_your_message: 'Send Your Message',
      successfully_sent_request: 'Successfully sent your request',
      loading: 'Loading....',
      topbar_title: 'Welcome to Our store Multikart',
      call_us: 'Call Us',
      brands: 'Brands',
      home: 'home',
      features: 'features',
      new: 'new',
      visit: 'Visit',
      now: 'now',
      blog: 'blog',
      new_brands: 'New Brands',
      recent_story: 'Recent Story',
      section: 'Sections',
      form_blog: 'from the blog',
      blog_left_sidebar: 'left sidebar',
      blog_right_sidebar: 'right sidebar',
      blog_detail: 'blog detail',
      category_left_sidebar: 'left sidebar',
      category_right_sidebar: 'right sidebar',
      category_no_sidebar: 'no sidebar',
      category_metro: 'metro',
      category_full_width: 'full width',
      products: 'products',
      left_sidebar: 'left sidebar',
      right_sidebar: 'right sidebar',
      no_sidebar: 'no sidebar',
      three_col_thumbnail_left: 'Thumbnail left',
      three_col_thumbnail_right: 'Thumbnail right',
      thumbnail_below: 'thumbnail below',
      accordian_details: 'accordian details',
      thumbnail_left: 'image left',
      thumbnail_right: 'image right',
      vertical_tab: 'vertical tab',
      pages: 'pages',
      about_us: 'about us',
      post_by: 'Posted By',
      lookbook: 'lookbook',
      blog_empty: 'Blog Empty ',
      login: 'login',
      register: 'register',
      logout: 'Logout',
      search: 'search',
      wishlist: 'wishlist',
      cart: 'cart',
      collection: 'collection',
      forget_password: 'forget password',
      contact: 'contact us',
      checkout: 'checkout',
      compare: 'compare',
      order_success: 'order_success',
      dashboard: 'Dashboard',
      FAQ: 'FAQ',
      shop: 'Shop',
      mens_fashion: 'mens fashion',
      top: 'top',
      bottom: 'bottom',
      ethic_wear: 'ethic wear',
      sports_wear: 'sports wear',
      shirts: 'shirts',
      women_fashion: 'womens fashion',
      dresses: 'dresses',
      skirts: 'skirts',
      westarn_wear: 'westarn wear',
      ethnic_wear: 'ethnic wear',
      sport_wear: 'aport wear',
      bottom_wear: 'bottom wear',
      kids_fashion: 'kidss fashion',
      accessories: 'Accessories',
      fashion_jewellery: 'Fashion Jewellery',
      caps_and_hats: 'caps and hats',
      precious_jewellery: 'precious jewellery',
      necklaces: 'necklaces',
      earrings: 'earrings',
      rings_wrist_wear: 'rings & wrist wear',
      men_accessories: 'mens accessories',
      ties: 'ties',
      cufflinks: 'cufflinks',
      pockets_squares: 'pockets squares',
      helmets: 'helmets',
      scarves: 'scarves',
      phone_cases: 'phone cases',
      my_account: 'My Account',
      fashion: 'Fashion',
      beauty: 'Beauty',
      electronic: 'Electronic',
      furniture: 'Furniture',
      kids: 'Kids',
      pets: 'Pets',
      vegetables: 'Vegetables',
      watch: 'Watch',
      theme_elements: 'theme elements',
      element_title: 'element title',
      collection_banner: 'collection banner',
      home_slider: 'home slider',
      category: 'category',
      service: 'service',
      image_size_ratio: 'image size ratio',
      product_elements: 'product elements',
      product_box: 'product box',
      product_slider: 'product slider',
      no_slider: 'no slider',
      multi_slider: 'multi slider',
      tab: 'tab',
      fax: 'Fax',
      email_template: 'email template',
      portfolio: 'portfolio',
      portfolio_grid_2: 'portfolio grid 2',
      portfolio_grid_3: 'portfolio grid 3',
      portfolio_grid_4: 'portfolio grid 4',
      portfolio_masonary_2: 'masonary 2',
      portfolio_masonary_3: 'masonary 3',
      portfolio_masonary_4: 'masonary 4',
      showing_products: 'Showing Products',
      portfolio_masonary_full: 'masonary full width',
      result: 'Result',
      Vendors: 'Vendor',
      product_details: 'Product details',
      option: 'Option',
      details: 'Details',
    },
  },
};

export default module;
