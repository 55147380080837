import React from 'react';
import { Link } from 'react-router-dom';
import { SERVER_LINK } from '../../../../constants/conections';

const Vendor = props => {
  const { product } = props;

  let RatingStars = [];
  for (var i = 0; i < product.rating; i++) {
    RatingStars.push(<i className="fa fa-star" key={i} />);
  }

  return (
    <div className="product-box product-wrap">
      <div className="img-wrapper">
        <div className="lable-block">
          {product.new == true ? <span className="lable3">new</span> : ''}
          {/*           {product.sale == true ? <span className="lable4">on sale</span> : ''} */}
        </div>
        <div
          className="front"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Link to={`${process.env.PUBLIC_URL}/products/${product.id}`}>
            <img
              style={{ width: '250px', height: '250px', objectFit: 'cover' }}
              src={
                product.logo
                  ? SERVER_LINK + product.logo.url
                  : process.env.PUBLIC_URL + 'assets/images/vendor.jpeg'
              }
              className="img-fluid"
              alt=""
            />
          </Link>
        </div>
      </div>
      <div className="product-detail  text-center">
        <div>
          <div className="rating">{RatingStars}</div>
          <Link to={`${process.env.PUBLIC_URL}/products/${product.id}`}>
            <h6>{product.name}</h6>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
