import React, { Component, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import { SERVER_LINK } from '../../constants/conections';
import { withTranslate } from 'react-redux-multilingual';
import Skeleton from 'react-loading-skeleton';
import Pagination from 'react-responsive-pagination';
import store from '../../store';

const LIMIT_BLOG = 4;
const BLOGS = gql`
  query blogsConnection($limit: Int, $start: Int) {
    blogsConnection(limit: $limit, start: $start) {
      values {
        id
        created_at
        Title
        titleAr
        subTitle
        subTitleAr
        MainImage {
          url
        }
        imageSlider {
          url
        }

        Content
      }
      aggregate {
        count
        totalCount
      }
    }
  }
`;
const RightSidebar = props => {
  const [page, setPage] = useState(0);
  const [pageCurrect, setPageCurrect] = useState(1);
  const { data, loading, error } = useQuery(BLOGS, {
    variables: {
      limit: LIMIT_BLOG,
      start: page,
    },
  });
  const onPageChange = selectedItem => {
    console.log(selectedItem);
    if (selectedItem !== 1) {
      setPage((selectedItem - 1) * LIMIT_BLOG);
      setPageCurrect(selectedItem);
    } else {
      setPageCurrect(1);
      setPage(0);
    }
  };
  const { translate } = props;
  return !error ? (
    <div style={{ paddingBottom: '20px' }}>
      <Breadcrumb title={translate('blog')} />

      {/*Blog Right Sidebar section*/}
      {!loading ? (
        data &&
        data.blogsConnection &&
        data.blogsConnection.values.length > 0 ? (
          <section className="section-b-space  blog-page">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-8 col-md-7 ">
                  {data.blogsConnection.values.map((data, index) => {
                    return (
                      <div key={index} className="row blog-media">
                        <div className="col-xl-6">
                          <div className="blog-left">
                            <Link
                              to={`${process.env.PUBLIC_URL}/blog/details/${
                                data.id
                              }`}
                            >
                              <img
                                style={{
                                  height: '230px',
                                  objectFit: 'cover',
                                  width: '100%',
                                }}
                                src={
                                  data.MainImage != null
                                    ? SERVER_LINK + data.MainImage.url
                                    : `${
                                        process.env.PUBLIC_URL
                                      }/assets/images/blog/1.jpg`
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="blog-right">
                            <div>
                              <h6>
                                {moment(data.created_at).format('DD MMMM YYYY')}
                              </h6>
                              <Link
                                to={`${process.env.PUBLIC_URL}/blog/details/${
                                  data.id
                                }`}
                              >
                                <h4>
                                  {store.getState() &&
                                  store.getState().Intl &&
                                  store.getState().Intl.locale === 'ar'
                                    ? data.titleAr
                                    : data.Title}
                                </h4>
                              </Link>

                              <p>
                                <b>
                                  {store.getState() &&
                                  store.getState().Intl &&
                                  store.getState().Intl.locale === 'ar'
                                    ? data.subTitleAr
                                    : data.subTitle}
                                </b>
                              </p>
                              <ul className="post-social">
                                {/*   <li><i className="fa fa-heart"></i> 5 Hits</li>
                        <li><i className="fa fa-comments"></i> 10 Comment</li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/*      <div className="col-xl-3 col-lg-4 col-md-5">
                                <div className="blog-sidebar">
                                    <div className="theme-card">
                                        <h4>Recent Blog</h4>
                                        <ul className="recent-blog">
                                            <li>
                                                <div className="media">
                                                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/blog/1.jpg`} alt="Generic placeholder image" />
                                                        <div className="media-body align-self-center">
                                                            <h6>25 Dec 2018</h6>
                                                            <p>0 hits</p>
                                                        </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/blog/2.jpg`} alt="Generic placeholder image" />
                                                        <div className="media-body align-self-center">
                                                            <h6>25 Dec 2018</h6>
                                                            <p>0 hits</p>
                                                        </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/blog/3.jpg`} alt="Generic placeholder image" />
                                                        <div className="media-body align-self-center">
                                                            <h6>25 Dec 2018</h6>
                                                            <p>0 hits</p>
                                                        </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/blog/4.jpg`} alt="Generic placeholder image" />
                                                        <div className="media-body align-self-center">
                                                            <h6>25 Dec 2018</h6>
                                                            <p>0 hits</p>
                                                        </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/blog/5.jpg`} alt="Generic placeholder image" />
                                                        <div className="media-body align-self-center">
                                                            <h6>25 Dec 2018</h6>
                                                            <p>0 hits</p>
                                                        </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="theme-card">
                                        <h4>Popular Blog</h4>
                                        <ul className="popular-blog">
                                            <li>
                                                <div className="media">
                                                    <div className="blog-date">
                                                        <span>03 </span>
                                                        <span>may</span>
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <h6>Injected humour the like</h6>
                                                        <p>0 hits</p>
                                                    </div>
                                                </div>
                                                <p>it look like readable English. Many desktop publishing text. </p>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <div className="blog-date">
                                                        <span>03 </span>
                                                        <span>may</span>
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <h6>Injected humour the like</h6>
                                                        <p>0 hits</p>
                                                    </div>
                                                </div>
                                                <p>it look like readable English. Many desktop publishing text. </p>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <div className="blog-date">
                                                        <span>03 </span>
                                                        <span>may</span>
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <h6>Injected humour the like</h6>
                                                        <p>0 hits</p>
                                                    </div>
                                                </div>
                                                <p>it look like readable English. Many desktop publishing text. </p>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <div className="blog-date">
                                                        <span>03 </span>
                                                        <span>may</span>
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <h6>Injected humour the like</h6>
                                                        <p>0 hits</p>
                                                    </div>
                                                </div>
                                                <p>it look like readable English. Many desktop publishing text. </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </section>
        ) : (
          <div class="container-fluid mt-100">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  {/* <div className="card-header">
                  <h5>Cart</h5>
                </div> */}
                  <div className="card-body cart">
                    <div className="col-sm-12 empty-cart-cls text-center">
                      {' '}
                      <img
                        src="https://readytoinspire.com/wp-content/uploads/2021/03/blog.jpg"
                        width="100%"
                        height="400px"
                        className="img-fluid mb-4 mr-3"
                      />
                      <h3>
                        <strong> {translate('blog_empty')}</strong>
                      </h3>
                      {/* <h4>Add something to make me happy :)</h4>{' '} */}
                      <Link
                        to="/"
                        className="btn btn-primary cart-btn-transform m-3"
                        data-abc="true"
                      >
                        {translate('home')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <section className="section-b-space  blog-page">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-8 col-md-7 ">
                {Array(5)
                  .fill(1)
                  .map((data, index) => {
                    return (
                      <div
                        className="row blog-media"
                        style={{ height: '400px' }}
                      >
                        <div className="">
                          <div className="blog-left">
                            <Skeleton
                              className="img-fluid mb-4 mr-3"
                              width={400}
                              height={400}
                            />
                            <div
                              style={{
                                position: 'relative',
                                left: '100%',
                                bottom: '418px',
                              }}
                            >
                              <h4>
                                <Skeleton />
                              </h4>
                              {Array(12)
                                .fill(0)
                                .map((data2, index2) => {
                                  return (
                                    <div key={index2}>
                                      {' '}
                                      <p>
                                        {' '}
                                        <Skeleton />
                                      </p>{' '}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      )}
      <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
        {!error &&
          data &&
          data.blogsConnection &&
          data.blogsConnection.aggregate && (
            <Pagination
              current={pageCurrect}
              total={Math.ceil(
                data.blogsConnection.aggregate.count / LIMIT_BLOG
              )}
              onPageChange={onPageChange}
            />
          )}
      </div>
    </div>
  ) : (
    'error'
  );
};

export default withTranslate(RightSidebar);
