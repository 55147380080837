import React, { Component, useState } from 'react';
import Slider from 'react-slick';
import '../common/index.scss';

// import custom Components
import ProductListing from './common/product-listing';
import Breadcrumb from '../common/breadcrumb';
import FilterBar from './common/filter-bar';
import { useQuery, gql } from '@apollo/client';
import Pagination from 'react-responsive-pagination';
import Skeleton from 'react-loading-skeleton';

const LIMIT_PRODUCT = 20;
const GET_PRODUCTS = gql`
  query productsConnection($limit: Int, $start: Int) {
    productsConnection(limit: $limit, start: $start) {
      aggregate {
        count
        totalCount
      }
      values {
        id
        title
        description
        image {
          id
          url
        }

        featureProduct {
          id
          title
          value
          isShow
        }
        images {
          id
          url
        }
        vendors {
          id
          name
        }
      }
    }
  }
`;
const CollectionNoSideBar = () => {
  const [page, setPage] = useState(0);
  const [pageCurrect, setPageCurrect] = useState(1);
  const [layoutColumns, setLayoutColumns] = useState(3);
  const { data, loading, error, fetchMore } = useQuery(GET_PRODUCTS, {
    variables: {
      limit: LIMIT_PRODUCT,
      start: page,
    },
  });
  function LayoutViewClicked(colums) {
    setLayoutColumns(colums);
  }
  const onPageChange = selectedItem => {
    setPage((selectedItem - 1) * LIMIT_PRODUCT);

    setPageCurrect(selectedItem);

    console.log(selectedItem);
  };

  return (
    <div>
      <Breadcrumb title={'Products'} />

      {/*Section Start*/}

      <section className="section-b-space">
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="collection-content col">
                <div className="page-main-content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-12">
                        {/* <div className="top-banner-wrapper">
                          <a href="#">
                            <img
                              src={`${
                                process.env.PUBLIC_URL
                              }/assets/images/mega-menu/2.jpg`}
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                          <div className="top-banner-content small-section">
                            <h4>fashion</h4>
                            <h5>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </h5>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.{' '}
                            </p>
                          </div>
                        </div> */}
                        <div className="collection-product-wrapper">
                          <div className="product-top-filter">
                            <div className="container-fluid p-0">
                              <div className="row">
                                <div className="col-12">
                                  <FilterBar
                                    start={pageCurrect}
                                    count={
                                      !loading &&
                                      !error &&
                                      data.productsConnection.aggregate.count
                                    }
                                    onLayoutViewClicked={colmuns =>
                                      LayoutViewClicked(colmuns)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {!loading && !error && data ? (
                            <div className="product-wrapper-grid">
                              <div className="container-fluid">
                                <div className="">
                                  <ProductListing
                                    fetchMore={fetchMore}
                                    dataProducts={
                                      data && data.productsConnection
                                        ? data.productsConnection
                                        : {
                                            values: [],
                                            aggregate: { count: 0 },
                                          }
                                    }
                                    colSize={layoutColumns}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="product-wrapper-grid">
                              <div className="container-fluid">
                                <div className="row">
                                  {Array(5)
                                    .fill(5)
                                    .map((data, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className=" col-xl-3 col-md-6 col-grid-box"
                                        >
                                          <Skeleton width={250} height={250} />
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            style={{ marginTop: '28px', marginBottom: '10px' }}
                          >
                            {!error &&
                              data &&
                              data.productsConnection &&
                              data.productsConnection.aggregate && (
                                <Pagination
                                  current={pageCurrect}
                                  total={Math.ceil(
                                    data.productsConnection.aggregate.count /
                                      LIMIT_PRODUCT
                                  )}
                                  onPageChange={onPageChange}
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Section End*/}
    </div>
  );
};

export default CollectionNoSideBar;
