import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { withTranslate } from 'react-redux-multilingual';
import { LoadingBar } from 'react-redux-loading-bar';
import Skeleton from 'react-loading-skeleton';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { connect } from 'react-redux';

const GET_INFO_WEBSITE = gql`
  query {
    info {
      id
      email
      phone
      logo {
        id
        previewUrl
        url
      }
      lat
      lang
      location
      description
      fix
      telPhone
    }
  }
`;
const containerStyle = {
  width: '400px',
  height: '400px',
};

const CREATE_CONTENT_US_FOR_USER = gql`
  mutation createContactUs(
    $lastName: String!
    $firstName: String!
    $message: String!
    $email: String!
  ) {
    createContactUs(
      input: {
        data: {
          last_name: $lastName
          first_name: $firstName
          email: $email
          message: $message
        }
      }
    ) {
      contactUs {
        id
      }
    }
  }
`;
const Contact = ({ translate, info }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: info
      ? info.googleMapsApiKey
      : 'AIzaSyAH6r1Q4q6m4z1iuOj5r5wqmDo_oAIjAmc',
  });
  const { data, loading, error } = useQuery(GET_INFO_WEBSITE);
  const onCompleted = () => {
    toast.success(translate('successfully_sent_request'));
  };
  const onError = () => {
    alert('error');
  };
  const [
    mutationCreateContentUsForUser,
    { loading: loadingCreateUser },
  ] = useMutation(CREATE_CONTENT_US_FOR_USER, {
    onCompleted: onCompleted,
    onError: onError,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = data => {
    mutationCreateContentUsForUser({
      variables: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        message: data.message,
      },
    });
    reset();
  };
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  return true ? (
    !error ? (
      <div>
        <Breadcrumb title={translate('contact')} />

        {/*Forget Password section*/}
        {
          <section className=" contact-page section-b-space">
            <div className="container">
              <div className="row section-b-space">
                <div className="col-lg-7 map">
                  {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"
                  allowFullScreen
                /> */}
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      ce
                      center={{
                        lat: info && info.lat ? info.lat : 31.8906326,
                        lng: info && info.lang ? info.lang : 35.1967756,
                      }}
                      options={{
                        zoom: 15,
                      }}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      <Marker
                        position={{
                          lat: info && info.lat ? info.lat : 31.8906326,
                          lng: info && info.lang ? info.lang : 35.1967756,
                        }}
                      >
                        <InfoWindow>
                          <div>
                            <h5>{data && info && info.name_web}</h5>
                            {info && (
                              <p style={{ textAlign: 'justify' }}>
                                {info.description}
                              </p>
                            )}
                          </div>
                        </InfoWindow>
                      </Marker>
                    </GoogleMap>
                  )}
                </div>
                {!loading ? (
                  <div className="col-lg-5">
                    <div className="contact-right">
                      <ul>
                        {((data && data.info && data.info.phone) ||
                          (data.info && data.info.telPhone)) && (
                          <li>
                            <div className="contact-icon">
                              <img
                                src={`${
                                  process.env.PUBLIC_URL
                                }/assets/images/icon/phone.png`}
                                alt="Generic placeholder image"
                              />
                              <h6>{translate('contact')}</h6>
                            </div>
                            <div className="media-body">
                              <p>{data && data.info && data.info.phone}</p>
                              <p>{data && data.info && data.info.telPhone}</p>
                            </div>
                          </li>
                        )}
                        {data && data.info && data.info.location && (
                          <li>
                            <div className="contact-icon">
                              <i
                                style={{ color: '#eb8831' }}
                                className="fa fa-map-marker"
                                aria-hidden="true"
                              />
                              <h6>{translate('address')}</h6>
                            </div>
                            <div className="media-body">
                              <p>{data && data.info && data.info.location}</p>
                            </div>
                          </li>
                        )}
                        {data && data.info && data.info.email && (
                          <li>
                            <div className="contact-icon">
                              <img
                                src={`${
                                  process.env.PUBLIC_URL
                                }/assets/images/icon/email.png`}
                                alt="Generic placeholder image"
                              />
                              <h6>{translate('email_address')}</h6>
                            </div>
                            <div className="media-body">
                              <p>{data && data.info && data.info.email}</p>
                            </div>
                          </li>
                        )}
                        {data && data.info && data.info.fix && (
                          <li>
                            <div className="contact-icon">
                              <i
                                style={{ color: '#eb8831' }}
                                className="fa fa-fax"
                                aria-hidden="true"
                              />
                              <h6>{translate('fax')}</h6>
                            </div>
                            <div className="media-body">
                              <p>{data && data.info && data.info.fix}</p>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-5">
                    {' '}
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <form
                    className="theme-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="name">{translate('firstName')}</label>
                        <input
                          {...register('firstName', { required: true })}
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder={translate('enter_first_name')}
                          required=""
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">{translate('lastName')}</label>
                        <input
                          {...register('lastName', { required: true })}
                          type="text"
                          className="form-control"
                          id="last-name"
                          placeholder={translate('enter_last_name')}
                          required=""
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="email">{translate('email')}</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          {...register('email', { required: true })}
                          placeholder={translate('enter_email')}
                          required=""
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="review">
                          {translate('write_your_message')}
                        </label>
                        <textarea
                          {...register('message', { required: true })}
                          className="form-control"
                          placeholder={translate('write_your_message')}
                          id="exampleFormControlTextarea1"
                          rows="6"
                        />
                      </div>

                      <div className="col-md-12">
                        <button
                          disabled={loadingCreateUser}
                          className="btn btn-solid"
                          type="submit"
                        >
                          {loadingCreateUser
                            ? translate('loading')
                            : translate('send_your_message')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    ) : (
      <>Error</>
    )
  ) : (
    <>LOADING</>
  );
};
const mapStateToProps = (state, Ownprops) => ({
  info: state.pageInfo,
});
export default connect(mapStateToProps)(withTranslate(Contact));
