import React, { Component } from 'react';

export default class ImageZoom extends Component {
  render() {
    const { image } = this.props;

    return (
      <img
        src={`${image}`}
        style={{
          minWidth: '398px',
          maxWidth: '398px',
          height: '400px',
          objectFit: 'cover',
        }}
        className="img-fluid image_zoom_cls-0"
      />
    );
  }
}
