import React, { Component, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import '../common/index.scss';
import { connect } from 'react-redux';
import './left-sidebar.scss';
// import custom Components
import Service from './common/service';
import BrandBlock from './common/brand-block';
import NewProduct from '../common/new-product';
import Breadcrumb from '../common/breadcrumb';
import DetailsWithPrice from './common/product/details-price';
import DetailsTopTabs from './common/details-top-tabs';
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions';
import ImageZoom from './common/product/image-zoom';
import SmallImages from './common/product/small-image';
import { useQuery, gql } from '@apollo/client';
import { SERVER_LINK } from '../../constants/conections';
import Skeleton from 'react-loading-skeleton';
const GET_PRODUCT_INFO = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      title
      description
      image {
        id
        url
      }
      images {
        id
        url
      }
      description_2
      subDescriptionAr
      featureProduct {
        id
        titleAr
        title
        value
        isShow
      }
      descriptionAr
      vendors {
        id
        name
      }
    }
  }
`;

const LeftSideBar = props => {
  const [nav2, setNav2] = useState(null);
  const [nav1, setNav1] = useState(null);

  const slider1 = useRef();
  const slider2 = useRef();

  // document.getElementById('idOfElement').classList.add('newClassName');

  useEffect(
    () => {
      setNav2(slider1.current);
      setNav1(slider1.current);
    },
    [slider1]
  );

  function filterClick() {
    document.getElementById('filter').style.left = '-15px';
  }
  function backClick() {
    document.getElementById('filter').style.left = '-365px';
  }

  const { data, loading, error } = useQuery(GET_PRODUCT_INFO, {
    variables: {
      id: props.productId,
    },
  });
  const { symbol, item, addToCart, addToCartUnsafe, addToWishlist } = props;
  var products = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    fade: true,
  };
  var productsnav = {
    slidesToShow: 3,
    swipeToSlide: true,
    arrows: false,
    dots: false,
    focusOnSelect: true,
  };

  return (
    <div id="slide-bar">
      {/*SEO Support*/}
      <Helmet>
        <meta
          name="description"
          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
        />
      </Helmet>
      {/*SEO Support End */}

      <Breadcrumb
        title={!loading && !error && data && data.product && data.product.title}
      />

      {/*Section Start*/}
      {!loading && !error && data ? (
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-sm-3 collection-filter" id="filter">
                  <div className="collection-mobile-back pl-5">
                    <span onClick={backClick} className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>

                  {/* <BrandBlock/> */}
                  {/* <Service/> */}
                  {/*side-bar single product slider start*/}
                  {/* <NewProduct/> */}
                  {/*side-bar single product slider end*/}
                </div>
                <div className="col-lg-9 col-sm-12 col-xs-12">
                  <div className="">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="filter-main-btn mb-2">
                          <span onClick={filterClick} className="filter-btn">
                            <i className="fa fa-filter" aria-hidden="true" />{' '}
                            filter
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 product-thumbnail">
                        {data.product && (
                          <Slider
                            {...products}
                            asNavFor={nav2}
                            ref={slider1}
                            className="product-slick"
                          >
                            {data.product.images.length > 0 ? (
                              data.product.images.map((vari, index) => (
                                <div key={index}>
                                  <ImageZoom image={SERVER_LINK + vari.url} />
                                </div>
                              ))
                            ) : (
                              <div>
                                <ImageZoom
                                  image={
                                    data.product.image
                                      ? SERVER_LINK + data.product.image.url
                                      : process.env.PUBLIC_URL +
                                        '/assets/images/cat1.jpg'
                                  }
                                />
                              </div>
                            )}
                          </Slider>
                        )}
                        {/* {data.product && (
                          <SmallImages
                            item={data.product.images}
                            settings={productsnav}
                            navOne={nav1}
                          />
                        )} */}
                      </div>
                      <DetailsWithPrice
                        dataProduct={data.product}
                        symbol={symbol}
                        item={item}
                        navOne={nav1}
                        addToCartClicked={addToCart}
                        BuynowClicked={addToCartUnsafe}
                        addToWishlistClicked={addToWishlist}
                      />
                    </div>
                  </div>
                  <DetailsTopTabs item={data.product} />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="section-b-space  blog-page">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-8 col-md-7 ">
                {Array(5)
                  .fill(1)
                  .map((data, index) => {
                    return (
                      <div
                        className="row blog-media"
                        style={{ height: '400px' }}
                      >
                        <div className="">
                          <div className="blog-left">
                            <Skeleton
                              className="img-fluid mb-4 mr-3"
                              width={400}
                              height={400}
                            />
                            <div
                              style={{
                                position: 'relative',
                                left: '100%',
                                bottom: '418px',
                              }}
                            >
                              <h4>
                                <Skeleton />
                              </h4>
                              {Array(1)
                                .fill(0)
                                .map((data2, index2) => {
                                  return (
                                    <div key={index2}>
                                      {' '}
                                      <p>
                                        {' '}
                                        <Skeleton />
                                      </p>{' '}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      )}
      {/*Section End*/}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    productId: productId,
    item: state.data.products.find(el => el.id == productId),
    symbol: state.data.symbol,
  };
};

export default connect(
  mapStateToProps,
  { addToCart, addToCartUnsafe, addToWishlist }
)(LeftSideBar);
