import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual';
import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations';
import { addPageInfo, getAllProducts } from './actions';

// Layouts

import Beauty from './components/layouts/beauty/main';

//Collection Pages
import CollectionNoSidebar from './components/collection/collection-no-sidebar';

// Product Pages
import LeftSideBar from './components/products/left-sidebar';

// Features
import Layout from './components/app';

// Extra Pages
import aboutUs from './components/pages/about-us';
import PageNotFound from './components/pages/404';

import Collection from './components/pages/collection';

import Contact from './components/pages/contact';

// Blog Pages
import RightSide from './components/blogs/right-sidebar';
import Details from './components/blogs/details';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from '@apollo/client';
import { SERVER_API_LINK } from './constants/conections';
import GridBrands from './components/features/portfolio/grid-brands';
import GridProductsByBrand from './components/features/portfolio/grid-productsByBrand';
import GridProductsByRightMed from './components/features/portfolio/grid-productByRightMed';

export const client = new ApolloClient({
  uri: SERVER_API_LINK,
  cache: new InMemoryCache(),
});

const GET_INFO_WEBSITE = gql`
  query {
    info {
      id
      email
      phone
      email
      location
      description
      name_web
      lat
      fix
      facebook
      twitter
      googleMapsApiKey
      Instagram
      descriptionAr
      locationAr
      logo {
        id
        url
      }
      facebook
    }
  }
`;
const Root = () => {
  const { data, loading, error } = useQuery(GET_INFO_WEBSITE);

  useEffect(() => {
    store.dispatch(getAllProducts());
    if (
      store.getState() &&
      store.getState().Intl &&
      store.getState().Intl.locale === 'ar'
    ) {
      document.getElementById('root').style.direction = 'rtl';

      document.getElementById('root').style.textAlign = 'initial';
    } else {
      document.getElementById('root').style.direction = 'ltr';

      document.getElementById('root').style.textAlign = 'initial';
      //  document.body.classList.remove('rtl');
    }
  }, []);

  useEffect(
    () => {
      if (data && !loading && !error) store.dispatch(addPageInfo(data.info));
    },
    [data]
  );
  return (
    <Provider store={store}>
      <IntlProvider translations={translations} locale="en">
        <BrowserRouter basename={'/'}>
          <ScrollContext>
            <Switch>
              <Layout>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Beauty}
                />
                {/*Routes For Layouts*/}
                {/*Routes For Features (Product Collection) */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/products`}
                  component={CollectionNoSidebar}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/products/:id`}
                  component={GridProductsByBrand}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/products/section/:id`}
                  component={GridProductsByRightMed}
                />
                {/*Routes For Single Product*/}
                <Route
                  path={`${process.env.PUBLIC_URL}/left-sidebar/product/:id`}
                  component={LeftSideBar}
                />
                {/*Routes For custom Features*/}
                {/*Routes For Extra Pages*/}
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/about-us`}
                  component={aboutUs}
                />
                {/* <Route
                  path={`${process.env.PUBLIC_URL}/pages/search`}
                  component={Search}
                /> */}
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/collection`}
                  component={Collection}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/contact`}
                  component={Contact}
                />{' '}
                {/*Features*/}
                {/*Theme Elements*/}
                {/*Product Elements*/}
                {/*Portfolios*/}
                <Route
                  path={`${process.env.PUBLIC_URL}/brands`}
                  component={GridBrands}
                />
                {/* <Route
                      path={`${process.env.PUBLIC_URL}/brands`}
                      component={GridCols}
                    />  */}
                {/* <Route
                      path={`${
                        process.env.PUBLIC_URL
                      }/features/portfolio-masonary/:columns`}
                      component={MasonaryGridCols}
                    /> */}
                {/*Blog Pages*/}
                <Route
                  path={`${process.env.PUBLIC_URL}/news`}
                  component={RightSide}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/blog/details/:id`}
                  component={Details}
                />
                {/* <Route
                  path={`${process.env.PUBLIC_URL}/blog/blog-page`}
                  component={BlogPage}
                /> */}
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/404`}
                  component={PageNotFound}
                />
                {/* <Route exact path="*" component={PageNotFound} /> */}
              </Layout>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  );
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <Root />
  </ApolloProvider>,
  document.getElementById('root')
);
