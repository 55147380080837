import React, { Component } from 'react';
import Slider from 'react-slick';

import Breadcrumb from '../common/breadcrumb';
import { Slider2, Team4, Slider3 } from '../../services/script';
import { useQuery, gql } from '@apollo/client';
import { SERVER_LINK } from '../../constants/conections';
import { MarkdownPreview } from 'react-marked-markdown';
import './about-style.scss';
import Skeleton from 'react-loading-skeleton';
import store from '../../store';
import { withTranslate } from 'react-redux-multilingual';
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const GET_ABOUT_DATA = gql`
  {
    about {
      id
      content
      content_Arabic
      slider_image {
        id
        url
      }
      teams {
        id
        name
        description
        position
        profile_image {
          url
          previewUrl
          id
        }
      }
    }
  }
`;
const aboutUs = props => {
  const { data, loading, error } = useQuery(GET_ABOUT_DATA);

  return (
    <div id="about-us">
      <Breadcrumb title={props.translate('about_us')} />
      {/*about section*/}
      {!loading ? (
        data &&
        data.about && (
          <section
            className="about-page  section-b-space"
            style={{ marginBottom: '20px' }}
          >
            <div className="container">
              <div className="row">
                {data.about.slider_image.length > 0 && (
                  <div className="col-lg-12">
                    <Slider {...settings}>
                      {data &&
                        data.about &&
                        data.about.slider_image &&
                        data.about.slider_image.map((data, index) => {
                          return (
                            <div className="banner-section">
                              <img
                                style={{ margin: 'auto' }}
                                src={
                                  data.url
                                    ? SERVER_LINK + data.url
                                    : `${
                                        process.env.PUBLIC_URL
                                      }/assets/images/about/about%20us.jpg`
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                )}
                <div className="col-sm-12">
                  {data &&
                    data.about &&
                    (data.about.content || data.about.content_Arabic) &&
                    (store.getState() &&
                    store.getState().Intl &&
                    store.getState().Intl.locale === 'ar' ? (
                      <MarkdownPreview
                        value={
                          data.about &&
                          data.about.content_Arabic &&
                          data.about.content_Arabic.replaceAll(
                            '/uploads/',
                            SERVER_LINK + '/uploads/'
                          )
                        }
                      />
                    ) : (
                      <MarkdownPreview
                        value={
                          data.about &&
                          data.about.content &&
                          data.about.content.replaceAll(
                            '/uploads/',
                            SERVER_LINK + '/uploads/'
                          )
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
          </section>
        )
      ) : (
        <div style={{ marginInline: '120px' }}>
          <Skeleton height="300px" />
          <br />
          <br />
          {Array(4)
            .fill(4)
            .map((data, index) => {
              return (
                <Skeleton
                  width={index == 0 ? '280px' : ''}
                  height={index == 0 ? '20px' : ''}
                />
              );
            })}{' '}
          <br />
          <br />
          {Array(4)
            .fill(4)
            .map((data, index) => {
              return (
                <Skeleton
                  width={index == 0 ? '300px' : ''}
                  height={index == 0 ? '20px' : ''}
                />
              );
            })}
          {Array(4)
            .fill(4)
            .map((data, index) => {
              return (
                <Skeleton
                  width={index == 0 ? '100px' : ''}
                  height={index == 0 ? '15px' : ''}
                />
              );
            })}
        </div>
      )}

      {/*Testimonial*/}
      {data && data.about && data.about.teams && data.about.teams.length > 0 && (
        <section className="testimonial small-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Slider
                  {...Slider2}
                  className="slide-2 testimonial-slider no-arrow"
                >
                  {data &&
                    data.about &&
                    data.about.teams &&
                    data.about.teams.length > 0 &&
                    data.about.teams.map((data, index) => {
                      return (
                        <div>
                          <div className="media">
                            <div className="text-center">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={
                                    data.profile_image && data.profile_image.url
                                      ? SERVER_LINK + data.profile_image.url
                                      : `${
                                          process.env.PUBLIC_URL
                                        }/assets/images/Avatarpicture1.jpg`
                                  }
                                  alt="#"
                                />
                              </div>
                              <h5>{data.name}</h5>
                              <h6>{data.position}</h6>
                            </div>
                            <div className="media-body">
                              <p>{data.description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* <div>
                  <div className="media">
                    <div className="text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/Avatarpicture1.jpg`}
                        alt="#"
                      />
                      <h5>Julia Carney</h5>
                      <h6>Sales Director</h6>
                    </div>
                    <div className="media-body">
                      <p>
                        you how all this mistaken idea of denouncing pleasure
                        and praising pain was born and I will give you a
                        complete account of the system, and expound the actual
                        teachings.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="media">
                    <div className="text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/Avatarpicture2.jpg`}
                        alt="#"
                      />
                      <h5>Lessie Ray</h5>
                      <h6>E-Commerce Director</h6>
                    </div>
                    <div className="media-body">
                      <p>
                        you how all this mistaken idea of denouncing pleasure
                        and praising pain was born and I will give you a
                        complete account of the system, and expound the actual
                        teachings.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="media">
                    <div className="text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/Avatarpicture3.jpg`}
                        alt="#"
                      />
                      <h5>Amado Wright</h5>
                      <h6>CEO</h6>
                    </div>
                    <div className="media-body">
                      <p>
                        you how all this mistaken idea of denouncing pleasure
                        and praising pain was born and I will give you a
                        complete account of the system, and expound the actual
                        teachings.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="media">
                    <div className="text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/Avatarpicture4.jpg`}
                        alt="#"
                      />
                      <h5>Nickolas Villanueva</h5>
                      <h6>District Sales Manager</h6>
                    </div>
                    <div className="media-body">
                      <p>
                        you how all this mistaken idea of denouncing pleasure
                        and praising pain was born and I will give you a
                        complete account of the system, and expound the actual
                        teachings.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="media">
                    <div className="text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/Avatarpicture5.jpg`}
                        alt="#"
                      />
                      <h5>Amie Archer</h5>
                      <h6>Sales Account Manager</h6>
                    </div>
                    <div className="media-body">
                      <p>
                        you how all this mistaken idea of denouncing pleasure
                        and praising pain was born and I will give you a
                        complete account of the system, and expound the actual
                        teachings.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="media">
                    <div className="text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/Avatarpicture6.jpg`}
                        alt="#"
                      />
                      <h5>Mark Jecno</h5>
                      <h6>Medical Supply Technician</h6>
                    </div>
                    <div className="media-body">
                      <p>
                        you how all this mistaken idea of denouncing pleasure
                        and praising pain was born and I will give you a
                        complete account of the system, and expound the actual
                        teachings.
                      </p>
                    </div>
                  </div>
                </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )}

      {/*Team Section*/}
      {/* <section id="team" className="team section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>Our Team</h2>
              <Slider {...Team4} className="team-4">
                <div>
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/Avatarpicture1.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                  <h4>Hileri Keol</h4>
                  <h6>CEo & Founder At Company</h6>
                </div>
                <div>
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/Avatarpicture2.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                  <h4>Hileri Keol</h4>
                  <h6>CEo & Founder At Company</h6>
                </div>
                <div>
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/Avatarpicture3.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                  <h4>Hileri Keol</h4>
                  <h6>CEo & Founder At Company</h6>
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/team/4.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                  <h4>Hileri Keol</h4>
                  <h6>CEo & Founder At Company</h6>
                </div>
                <div>
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/Avatarpicture4.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                  <h4>Hileri Keol</h4>
                  <h6>CEo & Founder At Company</h6>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section> */}

      {/*service layout*/}
      {/* <div className="container about-cls section-b-space">
          <section className="service border-section small-section ">
            <div className="row">
              <div className="col-md-4 service-block">
                <div className="media">
                  <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                  <div className="media-body">
                    <h4>free shipping</h4>
                    <p>free shipping world wide</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block">
                <div className="media">
                  <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                  <div className="media-body">
                    <h4>24 X 7 service</h4>
                    <p>online service for new customer</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block">
                <div className="media">
                  <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                  <div className="media-body">
                    <h4>festival offer</h4>
                    <p>new online special festival offer</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      {/*service layout end*/}
      <br />
    </div>
  );
};

export default React.memo(withTranslate(aboutUs));
