import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';
import store from '../../../../store';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: {
        right: '0px',
      },
    };
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({
        navClose: {
          right: '-410px',
        },
      });
    }
    if (window.innerWidth < 1199) {
      this.setState({
        navClose: {
          right: '-300px',
        },
      });
    }
  }

  openNav() {
    console.log('open');
    this.setState({
      navClose: {
        right: '0px',
      },
    });
  }
  closeNav() {
    this.setState({ navClose: { right: '-410px' } });
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector('#main-menu').classList.add('hover-unset');
    }
  }

  handleSubmenu = event => {
    if (event.target.classList.contains('sub-arrow')) return;

    if (event.target.nextElementSibling.classList.contains('opensubmenu'))
      event.target.nextElementSibling.classList.remove('opensubmenu');
    else {
      document.querySelectorAll('.nav-submenu').forEach(function(value) {
        value.classList.remove('opensubmenu');
      });
      document
        .querySelector('.mega-menu-container')
        .classList.remove('opensubmenu');
      event.target.nextElementSibling.classList.add('opensubmenu');
    }
  };

  handleMegaSubmenu = event => {
    if (event.target.classList.contains('sub-arrow')) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        'opensubmegamenu'
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        'opensubmegamenu'
      );
    else {
      document.querySelectorAll('.menu-content').forEach(function(value) {
        value.classList.remove('opensubmegamenu');
      });
      event.target.parentNode.nextElementSibling.classList.add(
        'opensubmegamenu'
      );
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <div>
        <div className="main-navbar">
          <div id="mainnav">
            <div className="toggle-nav" onClick={this.openNav.bind(this)}>
              <i className="fa fa-bars sidebar-bar" />
            </div>
            {store.getState() &&
            store.getState().Intl &&
            store.getState().Intl.locale === 'ar' ? (
              <ul className="nav-menu" style={this.state.navClose}>
                <li className="back-btn" onClick={this.closeNav.bind(this)}>
                  <div className="mobile-back text-right">
                    <span>Back</span>
                    <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                  </div>
                </li>

                <li onClick={this.closeNav.bind(this)}>
                  <Link
                    to={'/news'}
                    className="nav-link" /* onClick={(e) => this.handleSubmenu(e)} */
                  >
                    {translate('blog')}
                  </Link>
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>
                    {translate('contact')}
                  </Link>
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>
                    {translate('about_us')}
                  </Link>
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/products`}
                    className="nav-link"
                    // onClick={e => this.handleSubmenu(e)}
                  >
                    {translate('products')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/brands`}
                    // onClick={e => this.handleSubmenu(e)}
                    className="nav-link" /* onClick={(e) => this.handleSubmenu(e)} */
                  >
                    <span>
                      {translate('brands')}
                      <span className="sub-arrow" />
                    </span>
                  </Link>

                  <ul className="nav-submenu">
                    {this.props &&
                      this.props.brands &&
                      this.props.brands.map((data, index) => {
                        return (
                          <li>
                            <Link
                              style={{ textTransform: 'none' }}
                              to={`${process.env.PUBLIC_URL}/products/${
                                data.id
                              }`}
                              className="nav-link" /* onClick={(e) => this.handleSubmenu(e)} */
                            >
                              {data.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                {/* <ul className="nav-submenu">
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/brands`}
                        className="nav-link"
                      >
                        {translate('brands')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/products-right-med`}
                        className="nav-link" 
                      >
                        RightMed
                      </Link>
                    </li>
                  </ul> */}

                <li onClick={this.closeNav.bind(this)}>
                  <Link to="/" className="nav-link">
                    {translate('home')}
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="nav-menu" style={this.state.navClose}>
                <li className="back-btn" onClick={this.closeNav.bind(this)}>
                  <div className="mobile-back text-right">
                    <span>Back</span>
                    <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                  </div>
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link to="/" className="nav-link">
                    {translate('home')}
                  </Link>
                </li>

                <li onClick={this.closeNav.bind(this)}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/products`}
                    className="nav-link" /* onClick={(e) => this.handleSubmenu(e)} */
                  >
                    <span>
                      {translate('products')}
                      {/* <span className="sub-arrow" /> */}
                    </span>
                  </Link>

                  {/* <ul className="nav-submenu">
                    {brands.map((data, index) => {
                      return (
                        <li>
                          {' '}
                          <Link
                            to={`${process.env.PUBLIC_URL}/brands`}
                            className="nav-link" 
                          >
                            {translate('brands')}
                          </Link>
                        </li>
                      );
                    })}
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/products-right-med`}
                        className="nav-link" 
                      >
                        RightMed
                      </Link>
                    </li>
                  </ul> */}
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/brands`}
                    // onClick={e => this.handleSubmenu(e)}
                    className="nav-link" /* onClick={(e) => this.handleSubmenu(e)} */
                  >
                    <span>
                      {translate('brands')}
                      <span className="sub-arrow" />
                    </span>
                  </Link>

                  <ul className="nav-submenu">
                    {this.props &&
                      this.props.brands &&
                      this.props.brands.map((data, index) => {
                        return (
                          <li>
                            {' '}
                            <Link
                              to={`${process.env.PUBLIC_URL}/products/${
                                data.id
                              }`}
                              style={{ textTransform: 'none' }}
                              className="nav-link" /* onClick={(e) => this.handleSubmenu(e)} */
                            >
                              {data.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>
                    {translate('about_us')}
                  </Link>
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>
                    {translate('contact')}
                  </Link>
                </li>
                <li onClick={this.closeNav.bind(this)}>
                  <Link
                    to={'/news'}
                    className="nav-link" /* onClick={(e) => this.handleSubmenu(e)} */
                  >
                    {translate('blog')}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslate(NavBar);
