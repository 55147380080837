import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';

import { getTrendingCollection } from '../../../services/index';
import { Product4, Product5 } from '../../../services/script';
import { addToCart, addToWishlist, addToCompare } from '../../../actions/index';
import ProductItem from '../../features/product/common/product-style-five';
import Vendor from '../../features/product/common/vendor';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { withTranslate } from 'react-redux-multilingual';
import Skeleton from 'react-loading-skeleton';
const VENDORS = gql`
  query {
    vendors(limit: 6, sort: "created_at:desc") {
      id
      name
      logo {
        url
      }
    }
  }
`;
const NewVendors = props => {
  const { data, loading, error } = useQuery(VENDORS);

  return !loading && !error ? (
    <div>
      {/*Paragraph*/}
      {data && data.vendors.length > 0 && (
        <div className="title1  section-t-space">
          {/*         <h4>special offer</h4> */}
          <h2 className="title-inner1">{props.translate('new_brands')}</h2>
        </div>
      )}
      {/*Paragraph End*/}
      <section className="section-b-space p-t-0">
        <div className="container">
          <div className="row">
            <div className="col">
              <Slider {...Product5} className="product-4 product-m no-arrow">
                {data &&
                  data.vendors.map((product, index) => (
                    <div key={index}>
                      <Vendor
                        product={product}
                        /*             symbol={symbol} */
                        /*   onAddToCompareClicked={() => addToCompare(product)}
                      onAddToWishlistClicked={() => addToWishlist(product)}
                      onAddToCartClicked={() => addToCart(product, 1)} */
                        key={index}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <div className="row" style={{ marginTop: '60px', marginBottom: '60px' }}>
      {Array(4)
        .fill(3)
        .map(data => {
          return (
            <div
              className="col"
              style={{ marginRight: '10px', marginLeft: '10px' }}
            >
              <Skeleton height="200px" />
            </div>
          );
        })}
    </div>
  );
};

export default withTranslate(NewVendors);
