import React, { Component, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';
import { getFeatureImages } from '../../../services';
import { photos } from '../../../api/portfolio';
import { useQuery, gql } from '@apollo/client';
import { SERVER_LINK } from '../../../constants/conections';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';
import Skeleton from 'react-loading-skeleton';
import Pagination from 'react-responsive-pagination';

import { LIMIT_PRODUCT } from './grid-productsByBrand';
const GET_CATEGORYES = gql`
  query category($id: ID!) {
    category(id: $id) {
      name
      nameAr
      id
    }
  }
`;
const GET_PRODUCTS_BY_BRAND = gql`
  query productsConnection($categories: ID!, $limit: Int, $start: Int) {
    productsConnection(
      limit: $limit
      start: $start
      where: { categories: $categories }
    ) {
      values {
        id
        image {
          url
          id
        }
        vendors {
          id
          name
        }
      }
      aggregate {
        count
        totalCount
      }
    }
  }
`;

const GridProductsByRightMed = props => {
  const {
    data: dataCategory,
    loading: loadingCategory,
    errors: errorCategory,
  } = useQuery(GET_CATEGORYES, {
    variables: {
      id: props.match.params.id,
    },
  });
  const [page, setPage] = useState(0);

  const [categoryId, setCategoryId] = useState(undefined);

  const { data, loading, error } = useQuery(GET_PRODUCTS_BY_BRAND, {
    variables: {
      limit: LIMIT_PRODUCT,
      start: page,
      categories: props.match.params.id,
    },
  });

  const [columns, setColumns] = useState('col-lg-4 col-sm-6');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState(photos);
  const [pageCurrect, setPageCurrect] = useState(1);
  const onPageChange = selectedItem => {
    setPage((selectedItem - 1) * LIMIT_PRODUCT);

    setPageCurrect(selectedItem);

    console.log(selectedItem);
  };
  useEffect(
    () => {
      let result;
      switch ('3') {
        case '4':
          result = 'col-lg-3 col-sm-6';
          break;
        case '3':
          result = 'col-lg-4 col-sm-6';
          break;
        case '2':
          result = 'col-sm-6';
          break;
        default:
          result = 'col-sm-6';
          break;
      }
      setColumns(result);
    },
    [props]
  );

  const selectImage = (index, type) => {
    setImages(type === 'all' ? photos : getFeatureImages(photos, type));
    setIsOpen(true);
    setPhotoIndex(index);
  };
  const goToProduct = id => {
    props.history.push('/left-sidebar/product/' + id);
  };

  return (
    <div>
      <Breadcrumb
        title={
          !loadingCategory && !errorCategory
            ? dataCategory &&
              dataCategory.category &&
              (props.translate('products') === 'products'
                ? dataCategory.category.name
                : dataCategory.category.nameAr) +
                '/' +
                props.translate('products')
            : ''
        }
      />

      {/* Our Project Start */}
      <section className="portfolio-section grid-portfolio ratio2_3 portfolio-padding">
        <div className="container">
          <Tabs onSelect={index => console.log(index)} className="theme-tab">
            {!loadingCategory && !errorCategory ? (
              <TabList hidden className="tabs tab-title">
                <Tab onClick={() => setCategoryId(null)}>All</Tab>
              </TabList>
            ) : (
              <></>
            )}

            <div className="row zoom-gallery">
              {!error && !loading ? (
                data &&
                data.productsConnection &&
                data.productsConnection.values.length > 0 ? (
                  data.productsConnection.values.map((img, index) => (
                    <div
                      className={`isotopeSelector filter fashion ${columns}`}
                      key={`all-${index}`}
                    >
                      <div className="overlay">
                        <div
                          className="border-portfolio"
                          style={{ textAlign: 'center' }}
                        >
                          <div
                            className="overlay-background"
                            onClick={() => goToProduct(img.id)}
                          >
                            <i className="fa fa-eye" aria-hidden="true" />
                          </div>
                          <img
                            src={img.image ? SERVER_LINK + img.image.url : ''}
                            style={{ height: '280px', objectFit: 'cover' }}
                            className="img-fluid blur-up lazyload bg-img"
                          />
                          <h4 style={{ marginTop: '34px' }}>{img.title}</h4>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div
                      class="container-fluid mt-100"
                      style={{ paddingTop: '20px', paddingBottom: '20px' }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {/* <div className="card-header">
                  <h5>Cart</h5>
                </div> */}
                            <div className="card-body cart">
                              <div className="col-sm-12 empty-cart-cls text-center">
                                {' '}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    'assets/images/empty.png'
                                  }
                                  width="120"
                                  height="120"
                                  className="img-fluid mb-4 mr-3"
                                />
                                <h3>
                                  <strong>
                                    {' '}
                                    {props.translate('prdocut_empty')}
                                  </strong>
                                </h3>
                                {/* <h4>Add something to make me happy :)</h4>{' '} */}
                                <Link
                                  to="/"
                                  className="btn btn-primary cart-btn-transform m-3"
                                  data-abc="true"
                                >
                                  {props.translate('home')}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <div className="container">
                  <div className="row">
                    {Array(6)
                      .fill(0)
                      .map((data, index) => {
                        return (
                          <div key={index} className="col-lg-4 col-sm-6">
                            <Skeleton height={322} width={322} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </Tabs>
        </div>
      </section>
      <div style={{ marginTop: '20px', marginBottom: '40px' }}>
        {!error &&
          data &&
          data.productsConnection &&
          data.productsConnection.aggregate && (
            <Pagination
              current={pageCurrect}
              total={Math.ceil(
                data.productsConnection.aggregate.count / LIMIT_PRODUCT
              )}
              onPageChange={onPageChange}
            />
          )}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      )}
    </div>
  );
};

export default withTranslate(GridProductsByRightMed);
