import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual';

// Custom Components
import HeaderOne from './common/headers/header-one';
import HeaderTwo from './common/headers/header-two';
import HeaderThree from './common/headers/header-three';

import FooterOne from './common/footers/footer-one';
import FooterTwo from './common/footers/footer-two';
import FooterThree from './common/footers/footer-three';

// ThemeSettings
import ThemeSettings from './common/theme-settings';
import { useQuery, gql } from '@apollo/client';
const GET_VENDORS = gql`
  query {
    vendors {
      name

      id
    }
  }
`;
const App = props => {
  const { data, loading, error } = useQuery(GET_VENDORS);
  return (
    <div>
      <HeaderOne
        brands={!loading && !error && data ? data.vendors : []}
        logoName={'logo.png'}
      />
      {props.children}
      <FooterOne logoName={'logo.png'} />

      <ThemeSettings />
    </div>
  );
};

export default withTranslate(App);
