import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import { Slider3 } from '../../../services/script';
import { useQuery, gql } from '@apollo/client';
import { withTranslate } from 'react-redux-multilingual';
import { SERVER_LINK } from '../../../constants/conections';
import store from '../../../store';
import Skeleton from 'react-loading-skeleton';

const GET_BLOG_NEW = gql`
  query {
    blogs(limit: 8, sort: "created_at:asc") {
      id
      Title
      subTitle
      titleAr
      subTitleAr
      MainImage {
        url
      }
      Content
      created_at
    }
  }
`;
const BlogSection = ({ translate }) => {
  const { data, loading, error } = useQuery(GET_BLOG_NEW);

  return (
    <>
      {!loading ? (
        <section className="blog p-t-0 ratio3_2">
          <div className="container" style={{ paddingBottom: '25px' }}>
            <div className="row">
              <div className="col-md-12">
                {!loading &&
                !error &&
                data &&
                data.blogs &&
                data.blogs.length > 0 ? (
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="title1">
                          <h4>{translate('recent_story')}</h4>
                          <h2 className="title-inner1">
                            {translate('form_blog')}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <Slider {...Slider3} className="slide-3 no-arrow ">
                        {!loading &&
                          data &&
                          data.blogs.map((data, index) => {
                            return (
                              <div key={index}>
                                <div className="col-md-12">
                                  <Link
                                    to={`${
                                      process.env.PUBLIC_URL
                                    }/blog/details/${data.id}`}
                                  >
                                    <div
                                      className="classic-effect"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <img
                                        style={{
                                          objectFit: 'cover',
                                          width: '300px',
                                          height: '300px',
                                        }}
                                        src={`${SERVER_LINK}${data.MainImage &&
                                          data.MainImage.url}`}
                                        className="img-fluid"
                                        alt=""
                                      />
                                      <span />
                                    </div>
                                  </Link>
                                  <div className="blog-details">
                                    <h4 style={{ color: '#e6832b' }}>
                                      {store.getState() &&
                                      store.getState().Intl &&
                                      store.getState().Intl.locale === 'ar'
                                        ? data.titleAr
                                        : data.Title}
                                    </h4>
                                    <Link
                                      to={`${
                                        process.env.PUBLIC_URL
                                      }/blog/details`}
                                    >
                                      <p style={{ fontSize: '12px' }}>
                                        {store.getState() &&
                                        store.getState().Intl &&
                                        store.getState().Intl.locale === 'ar'
                                          ? data.subTitleAr
                                          : data.subTitle && data.subTitle}{' '}
                                      </p>
                                    </Link>
                                    <hr className="style1" />
                                    {/* <h6>by: Admin </h6> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div
          className="row"
          style={{ marginTop: '60px', marginBottom: '60px' }}
        >
          {Array(4)
            .fill(3)
            .map(data => {
              return (
                <div
                  className="col"
                  style={{ marginRight: '10px', marginLeft: '10px' }}
                >
                  <Skeleton height="200px" />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default withTranslate(BlogSection);
