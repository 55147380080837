import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';

import { getTrendingCollection } from '../../../services/index';
import { Product4, Product5 } from '../../../services/script';
import { addToCart, addToWishlist, addToCompare } from '../../../actions/index';
import ProductItem from '../../features/product/common/product-style-five';
import { useQuery, gql } from '@apollo/client';
import Product from '../../features/product/common/product';
import { withTranslate } from 'react-redux-multilingual';
import Skeleton from 'react-loading-skeleton';
const NEW_PRODUCTS = gql`
  query {
    products(limit: 12, sort: "created_at:desc") {
      id
      title
      vendors {
        name
      }
      image {
        url
        previewUrl
      }
      images {
        url
      }
    }
  }
`;
const NewProducts = props => {
  const {
    data: dataProducts,
    loading: loadingProducts,
    error: errorProducts,
  } = useQuery(NEW_PRODUCTS);
  /*   var properties;
  if (type === 'kids') {
    properties = Product5;
  } else {
    properties = Product4;
  } */

  return !loadingProducts && !errorProducts ? (
    <div>
      {/*Paragraph*/}
      {dataProducts && dataProducts.products.length > 0 && (
        <div className="title1  section-t-space">
          {/* <h4>special offer</h4> */}
          <h2 className="title-inner1">{props.translate('new_products')}</h2>
        </div>
      )}
      {/*Paragraph End*/}
      <section className="section-b-space p-t-0">
        <div className="container">
          <div className="row">
            <div className="col">
              <Slider {...Product4} className="product-4 product-m no-arrow">
                {dataProducts.products.map((product, index) => (
                  <div key={index}>
                    <Product
                      product={product}
                      /*      symbol={symbol} */
                      onAddToCompareClicked={() => addToCompare(product)}
                      onAddToWishlistClicked={() => addToWishlist(product)}
                      onAddToCartClicked={() => addToCart(product, 1)}
                      key={index}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <div>
      <div>
        <div className="container" style={{ marginTop: '50px' }}>
          <div className="row">
            {Array(3)
              .fill(3)
              .map(data => {
                return (
                  <div
                    className="col"
                    style={{ marginRight: '10px', marginLeft: '10px' }}
                  >
                    <Skeleton height="200px" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>{' '}
    </div>
  );
};

export default withTranslate(NewProducts);
