import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';
import { MarkdownPreview } from 'react-marked-markdown';
import { SERVER_LINK } from '../../../constants/conections';
import store from '../../../store';

const DetailsTopTabs = ({ item, translate }) => {
  return (
    <section className="tab-product m-0">
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          <Tabs className="tab-content nav-material">
            <TabList className="nav nav-tabs nav-material">
              <Tab className="nav-item">
                <span className="nav-link active">
                  <i className="icofont icofont-ui-home" />
                  {translate('option')}
                </span>
                <div className="material-border" />
              </Tab>
              <Tab className="nav-item">
                <span className="nav-link">
                  <i className="icofont icofont-man-in-glasses" />
                  {translate('details')}
                </span>
                <div className="material-border" />
              </Tab>
              {/* <Tab className="nav-item">
                <span className="nav-link">
                  <i className="icofont icofont-contacts" />
                  Video
                </span>
                <div className="material-border" />
              </Tab>
              <Tab className="nav-item">
                <span className="nav-link">
                  <i className="icofont icofont-contacts" />
                  Write Review
                </span>
                <div className="material-border" />
              </Tab> */}
            </TabList>
            {item && (
              <TabPanel className="tab-pane fade mt-4 show active">
                <table className="table table-striped mb-0">
                  <tbody>
                    {item.featureProduct.map((data, index) => {
                      return (
                        <tr>
                          <th>
                            {store.getState() &&
                            store.getState().Intl &&
                            store.getState().Intl.locale === 'ar'
                              ? data.titleAr
                              : data.title}{' '}
                            :
                          </th>
                          <td>{data.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </TabPanel>
            )}
            {item && (
              <TabPanel>
                <p className="mt-4 p-0">
                  {store.getState() &&
                  store.getState().Intl &&
                  store.getState().Intl.locale === 'ar' ? (
                    <MarkdownPreview
                      value={item.descriptionAr.replaceAll(
                        '/uploads/',
                        SERVER_LINK + '/uploads/'
                      )}
                    />
                  ) : (
                    <MarkdownPreview
                      value={
                        item.description_2 &&
                        item.description_2.replaceAll(
                          '/uploads/',
                          SERVER_LINK + '/uploads/'
                        )
                      }
                    />
                  )}
                </p>
              </TabPanel>
            )}
            {/* <TabPanel>
              <div className="mt-4 text-center">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    src="https://www.youtube.com/embed/BUWzX78Ye_8"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                </div>
              </div>
            </TabPanel> */}
            {/* <TabPanel>
              <form className="theme-form mt-4">
                <div className="form-row">
                  <div className="col-md-12 ">
                    <div className="media m-0">
                      <label>Rating</label>
                      <div className="media-body ml-3">
                        <div className="rating three-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter Your name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="review">Review Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="review"
                      placeholder="Enter your Review Subjects"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="review">Review Title</label>
                    <textarea
                      className="form-control"
                      placeholder="Wrire Your Testimonial Here"
                      id="exampleFormControlTextarea1"
                      rows="6"
                    />
                  </div>
                  <div className="col-md-12">
                    <button className="btn btn-solid" type="submit">
                      Submit YOur Review
                    </button>
                  </div>
                </div>
              </form>
            </TabPanel> */}
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default withTranslate(DetailsTopTabs);
