import React, { Component, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Skeleton from 'react-loading-skeleton';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';
import { getFeatureImages } from '../../../services';
import { photos } from '../../../api/portfolio';
import { useQuery, gql } from '@apollo/client';
import { SERVER_LINK } from '../../../constants/conections';
import { useNavigate } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';

const GET_BRANDS = gql`
  query {
    vendors {
      id
      name
      phone
      logo {
        id
        url
        previewUrl
      }
    }
  }
`;
const GridBrands = props => {
  const { data, loading, error } = useQuery(GET_BRANDS);
  const [columns, setColumns] = useState('col-sm-6');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState(photos);

  useEffect(
    nextProps => {
      /*      const {columns} = nextProps.match.params */
      let result;
      switch ('3') {
        case '4':
          result = 'col-lg-3 col-sm-6';
          break;
        case '3':
          result = 'col-lg-4 col-sm-6';
          break;
        case '2':
          result = 'col-sm-6';
          break;
        default:
          result = 'col-sm-6';
          break;
      }
      setColumns(result);
    },
    [props]
  );

  const selectImage = (index, type) => {
    setImages(type === 'all' ? photos : getFeatureImages(photos, type));
    setIsOpen(true);
    setPhotoIndex(index);
  };
  const goToProducts = id => {
    props.history.push('/products/' + id);
  };

  return (
    <div>
      <Breadcrumb title={props && props.translate('brands')} />

      {/* Our Project Start */}
      <section className="portfolio-section grid-portfolio ratio2_3 portfolio-padding">
        {!loading ? (
          <div
            className="container"
            style={{ paddingTop: '20px', paddingBottom: '20px' }}
          >
            <div className="row zoom-gallery">
              {data &&
                data.vendors.map((data, index) => (
                  <div
                    className={`  isotopeSelector filter fashion ${columns}`}
                    key={`all-${index}`}
                    style={{ textAlign: 'center' }}
                  >
                    <div
                      className="overlay"
                      style={{
                        maxWidth: '320px !important',
                        minWidth: '150px',
                        minHeight: '150px',
                        maxHeight: '320px !important',
                      }}
                    >
                      <div
                        className="border-portfolio"
                        style={{ paddingTop: '20px', paddingBottom: '20px' }}
                      >
                        <div
                          className="overlay-background"
                          onClick={() => goToProducts(data.id)}
                        >
                          <h3
                            style={{
                              paddingTop: '10px',
                              fontFamily: 'Leto',
                              color: 'white',
                            }}
                          >
                            {data.name}
                          </h3>
                          <i className="fa fa-eye" aria-hidden="true" />
                        </div>
                        <img
                          src={
                            data.logo
                              ? data.logo.url
                                ? SERVER_LINK + data.logo.url
                                : data.logo.previewUrl
                                ? data.logo.previewUrl
                                : 'iamge'
                              : 'image'
                          }
                          style={{
                            maxWidth: '250px',
                            maxHeight: '250px',
                            width: '250px',
                            height: '250px',
                            objectFit: 'cover',
                          }}
                          className="img-fluid blur-up lazyload bg-img"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              {Array(6)
                .fill(0)
                .map((data, index) => {
                  return (
                    <div key={index} className="col-lg-4 col-sm-6">
                      <Skeleton height={322} width={322} />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </section>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            photoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => photoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default withTranslate(GridBrands);
