import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SlideUpDown } from '../../../services/script';
import LogoImage from '../headers/common/logo';
import { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { BYWORK, SERVER_LINK } from '../../../constants/conections';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';

const FooterOne = props => {
  useEffect(() => {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown('footer-title');
    } else {
      var elems = document.querySelectorAll('.footer-title');
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = 'display: block';
      });
    }
  }, []);
  console.log(props.locale);
  const getDataInfo = _felidsName => {
    if (props) {
      if (props.info) return props.info[_felidsName];
      else return '';
    } else {
      return '';
    }
  };
  return (
    <footer className="footer-light">
      <div className="light-layout">
        <div className="container" />
      </div>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row footer-theme partition-f">
            <div className="col-lg-4 col-md-6">
              <div className="footer-title footer-mobile-title">
                <h4>about</h4>
              </div>
              <div className="footer-contant">
                <div className="footer-logo">
                  <LogoImage
                    logo={
                      SERVER_LINK +
                      (getDataInfo('logo') && getDataInfo('logo').url)
                    }
                  />
                </div>
                <p>
                  {' '}
                  {props.locale === 'ar'
                    ? getDataInfo('descriptionAr')
                    : getDataInfo('description')}
                </p>
                <div className="footer-social">
                  <ul>
                    {getDataInfo('facebook') && (
                      <li>
                        <a href={getDataInfo('facebook')} target="_blank">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                    )}

                    {getDataInfo('twitter') && (
                      <li>
                        <a href={getDataInfo('twitter')} target="_blank">
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      </li>
                    )}
                    {getDataInfo('Instagram') && (
                      <li>
                        <a href={getDataInfo('Instagram')} target="_blank">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col offset-xl-1">
              <div className="sub-title">
                <div className="footer-title">
                  <h4>{props.translate('pages')}</h4>
                </div>
                <div className="footer-contant">
                  <ul>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        {props.translate('home')}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/brands`}>
                        {props.translate('brands')}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>
                        {props.translate('contact')}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/news`}>
                        {' '}
                        {props.translate('blog')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="sub-title">
                <div className="footer-title">
                  <h4>{getDataInfo('name_web')} information</h4>
                </div>
                <div className="footer-contant">
                  <ul className="contact-list">
                    <li>
                      <i className="fa fa-map-marker" />
                      {props.locale === 'ar'
                        ? getDataInfo('locationAr')
                        : getDataInfo('location')}
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      {props.translate('call_us')}: {getDataInfo('phone')}
                    </li>
                    <li>
                      <i className="fa fa-envelope-o" />
                      {props.translate('email_us')}:{' '}
                      <a href={`mailto: ${getDataInfo('email')}`}>
                        {getDataInfo('email')}
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-fax" />
                      {props.translate('fax') + ':' + getDataInfo('fix')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sub-footer ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="footer-end">
                <p>
                  <i className="fa fa-copyright" aria-hidden="true" />
                  {new Date().getFullYear() + ' '}
                  {getDataInfo('name_web')} powered by {BYWORK}
                </p>
              </div>
            </div>
            {/* <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="payment-card-bottom">
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/visa.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/mastercard.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/paypal.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/american-express.png`}
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/discover.png`}
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state, Ownprops) => ({
  info: state.pageInfo,
  locale: state.Intl && state.Intl.locale,
});

export default connect(
  mapStateToProps,
  {}
)(withTranslate(FooterOne));
