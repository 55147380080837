import { ADD_PAGE_INFO } from '../constants/ActionTypes';

const initialState = {
  Instagram: 'https://www.instegram.com',
  description: 'momomaodasdasd',
  email: 'mohammad.aj@gmail.com',
  facebook: 'https://www.facebook.com',
  fix: '0222222222',
  id: '1',
  lat: 34.5,
  location: 'Ramallah-mason',
  logo: { url: '', id: '28' },
  name_web: 'RightMed',
  phone: '0592373906',
  twitter: 'https://www.twitter.com',
};
const pageInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAGE_INFO:
      const dataInfo = action && action.infoPage;

      return dataInfo;

    default:
      return state;
  }
};
export default pageInfoReducer;
