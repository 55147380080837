import React, { Component, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../../common/index.scss';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { useQuery, gql } from '@apollo/client';
import ThemeSettings from '../../common/theme-settings';
import './main.scss';
// Import custom c//omponents

import BlogSection from '../common/blogsection';
import NewProducts from '../common/new-products';
import NewVendors from '../common/new-vendors';
import { SERVER_LINK } from '../../../constants/conections';
import { withTranslate } from 'react-redux-multilingual';
import Skeleton from 'react-loading-skeleton';
const GET_SLIDER = gql`
  query {
    sliders {
      id
      title
      subTitle
      brand {
        id
        name
      }
      image {
        id
        url
      }
    }
  }
`;
const GET_SECTION = gql`
  query {
    categories {
      id
      name
      nameAr
      slug
    }
  }
`;
const settings = {
  dots: false,
  infinite: true,

  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
};
const Beauty = props => {
  const { data, loading, error } = useQuery(GET_SLIDER);
  const {
    data: dataCategories,
    loading: loadingCategories,
    error: errordataCategories,
  } = useQuery(GET_SECTION);
  // const [open, setOpen] = useState(false);
  // const onOpenModal = () => {
  //   setOpen(true);
  // };

  // const onCloseModal = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    document
      .getElementById('color')
      .setAttribute('href', `${process.env.PUBLIC_URL}/assets/css/color3.css`);
  }, []);
  const { translate, history } = props;
  const goToProductSection = id => {
    history.push(`/products/section/${id}`);
  };
  return (
    <div style={{ marginBottom: '30px' }}>
      <Helmet />
      {/* <HeaderOne logoName={'layout3/logo.png'} /> */}
      {!loading ? (
        <section className="p-0">
          <Slider {...settings} className="slide-1 home-slider">
            {!loading &&
              !error &&
              data.sliders.map((data, index) => {
                return (
                  <div key={index}>
                    <div
                      className={'home home35'}
                      style={{
                        backgroundImage:
                          data.image && `url(${SERVER_LINK + data.image.url})`,
                      }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col">
                            <div className="slider-contain">
                              <div>
                                <h1>{data.title}</h1>
                                {data.subTitle && <h4>{data.subTitle}</h4>}
                                {data.brand ? (
                                  <Link
                                    to={'/products/' + data.brand.id}
                                    className="btn btn-solid"
                                  >
                                    {translate('visit')} {data.brand.name}{' '}
                                    {translate('now')}
                                  </Link>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </section>
      ) : (
        <div>
          <Skeleton height={'400px'} />
        </div>
      )}

      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="title1">
                {/* <h4>{translate('section')}</h4> */}
                <h2 className="title-inner1">{translate('section')}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row"
            style={{
              display: 'inline-flex',
              'grid-template-columns': 'auto auto ',
              justifyContent: 'center',
            }}
          >
            {!errordataCategories && !loadingCategories ? (
              dataCategories &&
              dataCategories.categories.map((data, index) => {
                return (
                  <div
                    onClick={() => goToProductSection(data.id)}
                    key={index}
                    className=""
                    style={{ padding: '5px', textAlign: 'center' }}
                  >
                    <img
                      title={
                        translate('section') === 'Sections'
                          ? data.name
                          : data.nameAr
                      }
                      className="img-thumbnail image-category "
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/${data.slug}.jpg`
                      }
                    />
                  </div>
                );
              })
            ) : (
              <>
                <div
                  className=""
                  style={{ padding: '5px', textAlign: 'center' }}
                >
                  <Skeleton height="340px" width="380px" />
                </div>
                <div
                  className=""
                  style={{ padding: '5px', textAlign: 'center' }}
                >
                  <Skeleton height="340px" width="380px" />
                </div>
                <div
                  className=""
                  style={{ padding: '5px', textAlign: 'center' }}
                >
                  <Skeleton height="340px" width="380px" />
                </div>
                <div
                  className=""
                  style={{ padding: '5px', textAlign: 'center' }}
                >
                  <Skeleton height="340px" width="380px" />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <NewProducts />

      <NewVendors />
      {/*Product slider End*/}

      {/*Blog Section*/}

      <BlogSection />

      {/*Blog Section end*/}

      {/*Instagram Section*/}
      {/* <div className="section-b-space">
                    <Instagram type="watch" />
      </div> */}
      {/*Instagram Section End*/}

      {/* <FooterOne logoName={'layout3/logo.png'} /> */}

      <ThemeSettings />
    </div>
  );
};

export default withTranslate(Beauty);
